@import "variables";
@import "mixins";

/******* Base styles *******/
html {
	height: 100%;
}

body {
	height     : 100%;
	margin     : 0;
	padding    : 0;
	// for basic html elements we still must set our custom font(mat-typography doesn't do it)
	font-family: $ag-font-family !important;
	font-size  : 14px;
	line-height: 1.5;
}

a {
	outline        : none;
	text-decoration: none;
}

.mat-sidenav-content,
.mat-drawer-content {
	background: $ag-background;
}

.h-100 {
	height: 100% !important;
}

.h-20 {
	height: 20%;
}

.w-100 {
	width: 100% !important;
}

.w-80 {
	width: 80% !important;
}

.w-50 {
	width: 50% !important;
}

.w-40 {
	width: 40%;
}

.w-30 {
	width: 30% !important;
}

.w-20 {
	width: 20% !important;
}

.w-10 {
	width: 10% !important;
}

.mw-100 {
	max-width: 100%;
}

.fw-300 {
	font-weight: 300;
}

.fw-400 {
	font-weight: 400;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-800 {
	font-weight: 800;
}

.fw-bold {
	font-weight: bold !important;
}

.td-underline {
	text-decoration: underline !important;
}

.font-2 {
	font-size: 20px;
}

.lh {
	line-height: 27px;
}

.theme-container {
	max-width: $theme-max-width;
	margin   : 0 auto;
	// width: 100%;
}

.app.ag .mat-mdc-card {
	border-radius: 0;
}

.light-block.mat-mdc-card {
	background: rgba(0, 0, 0, 0.03) !important;
	box-shadow: none !important;
}

.transition {
	-webkit-transition: 0.3s;
	-moz-transition   : 0.3s;
	-ms-transition    : 0.3s;
	transition        : 0.3s;
}

.text-truncate {
	white-space  : nowrap;
	text-overflow: ellipsis;
	overflow     : hidden !important;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.divider {
	border-top: 1px solid rgba(204, 204, 204, 0.6);
}

.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
	font-size     : 14px;
	line-height   : 14px;
	height        : 14px;
	width         : 14px;
	vertical-align: middle;
}

.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm {
	font-size     : 18px;
	line-height   : 18px;
	height        : 18px;
	width         : 18px;
	vertical-align: middle;
}

.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
	// font-size     : 48px;
	// line-height   : 48px;
	// height        : 48px;
	// width         : 48px;
	vertical-align: middle;
}

.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
	// font-size     : 48px;
	// line-height   : 48px;
	// height        : 48px;
	// width         : 48px;
	vertical-align: middle;
}

.mat-icon.caret {
	width: 14px;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-button.flex-row-button {
	display       : flex;
	flex-direction: row;
	align-items   : center;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-wrapper {
	pointer-events: none;
	white-space   : pre-wrap;
}

/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list.reviews .mat-mdc-list-item .mat-list-item-content {
	align-items: start;
}

.mat-step-text-label {
	text-transform: uppercase;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-content {
	font-size: 16px;
}

.mat-mdc-row,
.mat-mdc-header-row {
	border-bottom-color: rgba(0, 0, 0, 0.12);
}

.selected-account-table {
	background: $main-menu-background-color !important;
}

.filter-sidenav .mat-drawer-inner-container {
	height    : auto;
	padding   : 2px;
	box-sizing: border-box;

	.mat-expansion-panel {
		border-radius: 0 !important;
	}
}

.app-dropdown .mat-mdc-menu-item {
	// height     : 48px;
	// line-height: 48px;
	font-size  : 14px;
}

.app-dropdown.account {
	.mat-mdc-menu-content {
		padding: 0;

		.user-info {
			padding: 16px;

			img {
				margin-right : 16px;
				border-radius: 3px;
			}

			.mat-icon {
				margin-top: -2px;
				color     : #fbc02d;
			}
		}
	}
}

.app-dropdown.mat-mdc-menu-panel {
	max-width: 500px; //Account panel was incorrectly displaying longer email address
}

.categories-dropdown.mat-mdc-menu-panel {
	max-height: 350px;
}

.categories-dropdown .mat-mdc-menu-item {
	height     : 24px;
	line-height: 24px;
	font-size  : 14px;
}

.sub-category button {
	padding-left: 30px;
}

.sub-category .sub-category button {
	padding-left: 50px;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 10px !important;
}

.p-2 {
	padding: 20px !important;
}

.p-3 {
	padding: 30px !important;
}

.py-1 {
	padding: 10px 0 !important;
}

.py-2 {
	padding: 20px 0 !important;
}

.py-3 {
	padding: 30px 0 !important;
}

.py-4 {
	padding: 40px 0 !important;
}

.py-5 {
	padding: 50px 0 !important;
}

.px-1 {
	padding: 0 10px !important;
}

.px-2 {
	padding: 0 20px !important;
}

.px-3 {
	padding: 0 30px !important;
}

.px-4 {
	padding: 0 40px !important;
}

.px-5 {
	padding: 0 50px !important;
}

.pb-1 {
	padding-bottom: 10px !important;
}

.pb-2 {
	padding-bottom: 20px !important;
}

.pt-0 {
	padding-top: 0px !important;
}

.pt-1 {
	padding-top: 10px !important;
}

.pt-2 {
	padding-top: 20px !important;
}

.py-2 {
	padding-bottom: 20px !important;
	padding-top   : 20px !important;
}

.pl-3 {
	padding-left: 30px;
}

.pl-4 {
	padding-left: 40px;
}

.m-auto {
	margin: auto;
}

.m-0 {
	margin: 0 !important;
}

.m-5 {
	margin: 5px !important;
}

.mt-1 {
	margin-top: 10px !important;
}

.mt-2 {
	margin-top: 20px !important;
}

.mt-3 {
	margin-top: 30px !important;
}

.mt-15 {
	margin-top: 150px !important;
}

.mb-0 {
	margin-bottom: 0px !important;
}

.mb-5 {
	margin-bottom: 5px !important;
}

.mb-1 {
	margin-bottom: 10px !important;
}

.mb-2 {
	margin-bottom: 20px !important;
}

.mr-1 {
	margin-right: 10px !important;
}

.ml-1 {
	margin-left: 10px !important;
}

.ml-2 {
	margin-left: 20px !important;
}

.ml-3 {
	margin-left: 30px !important;
}

.ml-4 {
	margin-left: 40px !important;
}

.my-7 {
	margin-top   : 7px !important;
	margin-bottom: 7px !important;
}

.full-width {
	width: 100% !important;
}

.full-height {
	height: 100% !important;
}

.d-block {
	display: block;
}

.d-flex {
	display: flex !important;
}

.d-inline {
	display: inline;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-start {
	justify-content: start;
}

.justify-content-end {
	justify-content: end;
}

.align-items-center {
	align-items: center;
}

.align-items-end {
	align-items: flex-end;
}

.align-items-start {
	align-items: flex-start;
}

.justify-content-space-between {
	justify-content: space-between;
}

.justify-content-space-around {
	justify-content: space-around;
}

.justify-content-space-evely {
	justify-content: space-evenly;
}

.info-bar {
	margin-top: 30px;

	.mat-mdc-card {
		height: 100px;

		.content {
			margin-left: 10px;

			p {
				font-weight: 500;
			}

			span {
				font-size: 13px;
			}
		}
	}
}

.social-icon {
	width  : 48px;
	height : 48px;
	cursor : pointer;
	fill   : #8f8f8f;
	outline: none;
}

/******* Toolbar *******/
.top-toolbar.mat-toolbar-row {
	height   : 32px;
	font-size: 13px;

	.sidenav-toggle {
		height       : 31px;
		line-height  : 31px;
		min-width    : 30px;
		padding      : 0;
		border-radius: 50;
		margin-right: 10px;

		.mat-icon {
			margin-right: 0 !important;
		}
	}

	.top-menu {
		a {
			text-decoration: none;
			font-weight    : 400;
			font-size      : 13px;
			line-height    : 31px;
			padding        : 0 10px;
			min-width      : 70px;
			border-radius  : 0;
		}

		a.mobile-menu-icon {
			min-width: 48px;
		}

		.mat-icon.more {
			height: 28px;
		}
	}
}

.flag-menu-title {
	vertical-align: top !important;
}

.top-menu-dropdown .mat-mdc-menu-item img {
	vertical-align: middle;
	margin-right  : 8px;
}

.cart-dropdown.mat-mdc-menu-panel {
	padding  : 6px 14px;
	font-size: 13px;
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	// .mat-list-container {
		height    : 160px;
		overflow-y: auto;

		.mat-mdc-list {
			padding: 0;

			.mat-mdc-list-item {
				/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
				// .mat-list-item-content {
					padding: 0;

					a {
						color    : $ag-accent-color;
						font-size: 12px;

						&:hover {
							text-decoration: underline;
							cursor         : pointer;
						}
					}
				// }
			}
		}
	// }
}

.managed-cart-dropdown.mat-mdc-menu-panel {
	width    : 400px;
	min-width: 400px;
	padding  : 6px 14px;
	font-size: 13px;
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
	.mat-list-container {
		height    : 160px;
		overflow-y: auto;

		.mat-mdc-list {
			padding: 0;

			.mat-mdc-list-item {
				/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
				/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
				/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
				/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
				.mat-list-item-content {
					padding: 0;

					a {
						color    : $ag-accent-color;
						font-size: 12px;

						&:hover {
							text-decoration: underline;
							cursor         : pointer;
						}
					}
				}
			}
		}
	}
}

.logo-toolbar.mat-toolbar-row {
	height: 104px;
}

.logo {
	font-size      : 28px;
	font-weight    : 900;
	text-transform : uppercase;
	text-decoration: none;
	color          : inherit;
}

.search-form {
	border-radius: 22px;
	overflow     : hidden;

	.categories {
		height       : 40px;
		width        : 180px;
		border-radius: 0;
	}

	input[type="text"] {
		border     : 0;
		outline    : none;
		padding    : 0 34px 0 12px;
		border-left: 1px solid #ccc;
	}

	.search-btn {
		margin-left: -20px;
		background : #fff !important;
		box-shadow : none !important;
	}
}

.search-dropdown.mat-mdc-menu-panel {
	border-radius: 26px;
	max-width    : 100%;
	min-height   : auto;

	.mat-mdc-menu-content {
		padding: 0;
	}
}

.top-cart {
	span {
		font-size  : 13px;
		font-weight: 400;
		line-height: 1.2;
	}
}

/******* Top navbar (menu) *******/
.top-navbar.mat-toolbar {
	height : 48px;
	padding: 0;

	a {
		min-width     : 70px;
		border-radius : 0;
		text-transform: uppercase;
		padding       : 6px 16px;
	}
}

.cdk-overlay-pane.mega-menu-pane {
	left : 0 !important;
	width: 100%;

	.mat-mdc-menu-panel {
		width        : 100%;
		max-width    : none;
		border-radius: 0;

		.mat-mdc-menu-content {
			padding: 0;
		}
	}

	.mega-menu-widget {
		position: relative;

		button {
			position   : absolute;
			top        : 10px;
			left       : 10px;
			font-weight: 400;
		}
		/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
		/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
		/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
		/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
		.mat-chip-list {
			position: absolute;
			bottom  : 10px;
			left    : 10px;
		}
	}
}

/******* Carousel *******/
button.swipe-arrow {
	position        : absolute;
	top             : 50%;
	margin-top      : -22px;
	z-index         : 10;
	background-image: none;
}

.swiper-pagination.white .swiper-pagination-bullet {
	background: #fff;
}

.swiper-pagination-bullet-active {
	width         : 12px;
	height        : 12px;
	vertical-align: -2px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	content: none;
}

/******* Product *******/
.product-item {
	position: relative;
	/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	.mat-chip-list {
		position: absolute;
		top     : 10px;
		right   : 10px;

		&.list-view {
			left: 10px;
		}
	}

	.image-link {
		display: block;
	}

	img {
		max-width    : 100%;
		margin-bottom: 12px;
	}

	.category {
		margin-bottom: 4px;
	}

	.title {
		display        : block;
		color          : inherit;
		text-decoration: none;
		font-weight    : 500;
		font-size      : 18px;
	}

	.prices {
		margin: 12px 0;

		.old-price {
			text-decoration: line-through;
			height         : 15px;
		}

		.new-price {
			font-size  : 16px;
			font-weight: 500;
		}
	}

	.icons {
		margin-top: 18px;
	}
}

.old-price {
	text-decoration: line-through;
}

/******* Featured Catalog *******/
.featured-catalog-item {
	display : contents !important;
	position: relative;
	/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	.mat-chip-list {
		position: absolute;
		top     : 10px;
		right   : 10px;

		&.list-view {
			left: 10px;
		}
	}

	.image-link {
		display: block;
	}

	img {
		max-width    : 100%;
		margin-bottom: 12px;
	}

	.category {
		margin-bottom: 4px;
	}

	.title {
		display        : block;
		color          : inherit;
		text-decoration: none;
		font-weight    : 500;
		font-size      : 18px;
	}

	.prices {
		margin: 12px 0;

		.old-price {
			text-decoration: line-through;
			height         : 15px;
		}

		.new-price {
			font-size  : 16px;
			font-weight: 500;
		}
	}

	.icons {
		margin-top: 18px;
	}
}

/******* Back to top *******/
.back-to-top {
	position        : fixed;
	width           : 40px;
	height          : 40px;
	cursor          : pointer;
	z-index         : 999999;
	right           : 20px;
	bottom          : 20px;
	opacity         : 0.5;
	color           : #fff;
	background-color: rgba(0, 0, 0, 0.75);
	border-radius   : 4px;

	&:hover {
		opacity: 0.9;
	}
}

/******* Responsive *******/
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
	.top-toolbar.mat-toolbar-row {
		padding: 0;

		.top-menu {
			margin-right: 0;

			a {
				min-width: 56px;
			}
		}

		.sidenav-toggle {
			min-width: 52px;
		}
	}

	.search-form input[type="text"] {
		width: 160px;
	}

	.search-form .categories {
		width: 140px;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
	.top-toolbar.mat-toolbar-row {
		padding: 0;

		.top-menu {
			margin-right: 0;
		}

		.sidenav-toggle {
			min-width: 52px;
		}
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
	.top-toolbar.mat-toolbar-row {
		padding: 0;

		.top-menu {
			margin-right: 0;
		}

		.sidenav-toggle {
			min-width: 52px;
		}
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

::-webkit-scrollbar {
	width : 8px;
	height: 8px;
}

::-webkit-scrollbar-button {
	width : 0px;
	height: 0px;
}

::-webkit-scrollbar-thumb {
	background   : #e1e1e1;
	border       : 0px none #ffffff;
	border-radius: 0px;

	&:hover {
		background: #cccccc;
	}

	&:active {
		background: #888888;
	}
}

::-webkit-scrollbar-track {
	background   : #666666;
	border       : 0px none #ffffff;
	border-radius: 0px;

	&:hover {
		background: #666666;
	}

	&:active {
		background: #333333;
	}
}

::-webkit-scrollbar-corner {
	background: transparent;
}

/* perfect-scrollbar */
.ps {
	-ms-touch-action  : auto;
	touch-action      : auto;
	overflow          : hidden !important;
	-ms-overflow-style: none;
	position          : relative;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
	.ps {
		overflow: auto !important;
	}
}

.ps__rail-x {
	display           : none;
	opacity           : 0;
	transition        : background-color 0.2s linear, opacity 0.2s linear;
	-webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
	height            : 7px;
	bottom            : 0px;
	position          : absolute;
}

.ps__rail-y {
	display           : none;
	opacity           : 0;
	transition        : background-color 0.2s linear, opacity 0.2s linear;
	-webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
	width             : 7px;
	right             : 0px;
	left              : auto !important;
	position          : absolute;
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
	display         : block;
	background-color: transparent;
}

.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y,
.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x>.ps__rail-x,
.ps--scrolling-y>.ps__rail-y {
	opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
	background-color: #eee;
	opacity         : 0.9;
}

.ps__thumb-x {
	background-color  : #aaa;
	border-radius     : 6px;
	transition        : background-color 0.2s linear, height 0.2s ease-in-out;
	-webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
	height            : 4px;
	bottom            : 1px;
	position          : absolute;
}

.ps__thumb-y {
	background-color  : #aaa;
	border-radius     : 6px;
	transition        : background-color 0.2s linear, width 0.2s ease-in-out;
	-webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
	width             : 4px;
	right             : 1px;
	position          : absolute;
}

.ps__rail-x:hover>.ps__thumb-x,
.ps__rail-x:focus>.ps__thumb-x {
	background-color: #999;
	height          : 7px;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y {
	background-color: #999;
	width           : 7px;
}

@supports (-ms-overflow-style: none) {
	.ps {
		overflow: auto !important;
	}
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
	.ps {
		overflow: auto !important;
	}
}

@media (min-width: 1380px) {
	.theme-container {
		max-width: 100%;
	}
}

.sidenav .mat-drawer-inner-container {
	overflow-x: hidden;
}

.pointer {
	cursor: pointer;
}

.mat-mdc-raised-button {
	box-shadow: none !important;
}

.dialog-no-padding .mat-mdc-dialog-container {
	padding   : 0;
	max-height: 100vh;
}

.primary-color {
	color: $ag-primary-color;
}

.banner-text {
	color         : $header-text-color;
	letter-spacing: 3px;
	text-transform: uppercase;
	font-size     : 14px;
}

.banner-text-color {
	color: $header-text-color;
}

.footer-text-color {
	color: $footer-text-color;
}

.active-parent>a {
	font-weight   : bold;
	letter-spacing: 3px;
}

.msg-banner-panel {
	display        : flex;
	justify-content: center;
}

.button-custom-style {
	letter-spacing: 3px;
	text-transform: uppercase;
	font-size     : 14px;
}

.text-custom-style {
	font-size     : 14px;
	letter-spacing: 3px;
}

.header-custom-style {
	text-transform: uppercase;
	letter-spacing: 3px;
}

.mat-footer-row-border-top {
	border-top-width: 1px;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-toggle-focus-indicator {
	display: none;
}

.total-info-header {
	float      : left;
	font-weight: bold;
	color      : #333;
	font-size  : 14px;
}

.total-info-value {
	float        : right;
	padding-right: 10px;
	font-weight  : normal;
}

.terms-link {
	color: #d50032;
}

.dark-background-search-form {
	background-color: $main-menu-background-color;
	padding-top     : 15px;
	padding-left    : 25px;
	padding-right   : 25px;
}

.main-header {
	background-color: $header-background-color;
}

.main-section {
	background-color: $main-menu-background-color;
}

.bold {
	font-weight: bold;
}

.hidden {
	visibility: hidden;
	display   : none;
}

.focusable-item {

	&:focus,
	&:focus-within,
	&:focus-within {
		border: $focus-border-width solid $focus-border-color;
	}
}

.close-dialog-icon {
	margin-bottom: 10px !important;

	img {
		max-height: 20px;
		width     : auto;
		cursor    : pointer;
	}
}

.uppercase {
	text-transform: uppercase;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin            : 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.cancel-search-button {
	letter-spacing: normal;
	.mdc-button {
		font-weight: bold;
		color      : $ag-accent-color;
		font-weight: bold;
	}
}

.notification-popup {
	display       : flex;
	flex-direction: column;
	align-items   : flex-start;

	:first-child {
		margin-bottom: 20px;
	}

	:nth-child(2),
	:nth-child(3) {
		margin-bottom: 30px;
	}

	:last-child {
		font-weight: 500;
	}
}

.background-color-light-500 {
	background-color: #f0f0f0 !important;
}

.color-accent-500 {
	color: #db0032 !important;
}

.footer-text-color {
	color: #333;
}

.mat-mdc-raised-button.mat-accent {
	background-color: #db0032;
}

.mat-mdc-raised-button,
.app.ag .mat-mdc-outlined-button {
	letter-spacing  : 3px;
	text-transform  : uppercase;
	font-size       : 14px;
	background-color: #333;
	color           : white;
}

.mat-mdc-raised-button {
    &:hover:not(:disabled) {
      background-color: $ag-accent-color;
    }
  }

.mat-mdc-input-element {
	caret-color: #333;
}

.app.ag .mat-mdc-fab.mat-primary,
.app.ag .mat-mdc-unelevated-button.mat-primary,
.app.ag .mat-mdc-mini-fab.mat-primary,
.app.ag .mat-mdc-raised-button.mat-primary:not(:disabled) {
	background-color: #333;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-label {
	/*change color of label*/
	color: #333 !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-accent .mat-checkbox-background, 
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-primary .mat-pseudo-checkbox-checked, 
.mat-primary .mat-pseudo-checkbox-indeterminate {
	background-color:$ag-accent-color;
}

/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
	font-family: $ag-font-family;
	color: #333 !important;
}

.red-mat-tooltip {
	background: #db0033c3 !important;
	font-size : 13px !important;
}

.highlight-selected {
	background-color: #ffc3c3 !important;
}

.mat-calendar-body-selected {
	background-color: $ag-accent-color !important;
}

.mat-calendar-body-in-range::before {
	background: #ffc3c3;
}
