/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import "./styles/variables";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./styles/base";
@import "./styles/admin";
@import "./styles/theme";
@import "./styles/gradients";
@import "./styles/rtl";
@import "./styles/skins/ag";
@import "./styles/tab";

.cdk-overlay-container {
  z-index: 2000;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/fonts/basic/HelveticaNeue.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueMedium";
  src: url("./assets/fonts/medium/HelveticaNeue-Medium.ttf") format("truetype");
}

/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v82/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
  font-display: block;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

// !important is needed to overwrite angular material default style
.no-box-shadow {
  box-shadow: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: none !important;
}

.mat-stepper-vertical-line::before {
  border-left-width: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  font-weight: bold;
}

.app.ag {
  .mat-mdc-slide-toggle {
    .mdc-switch__track::before {
      background-color: #9f9ea4 !important;
    }
    .mdc-switch__shadow {
      background-color: #fafafa !important;
    }
  }
  .mat-mdc-slide-toggle-checked {
    .mdc-switch__track::after {
      background-color: #9f9ea4 !important;
    }
    .mdc-switch__shadow {
      background-color: #329832 !important;
    }
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.6em) scale(0.8);
  width: 155%;
}
